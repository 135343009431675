import React, { useEffect, useState } from "react"
import classnames from "classnames"

/* Import Global Components */
import FadeInCharactersRandomly from "~components/animations/fadeInCharactersRandomly"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./text.css"

const TextSlide = ({ active, copy, delay = 15 }) => {
  const [slideVisited, setSlideVisited] = useState(false)

  useEffect(() => {
    if (active) {
      setSlideVisited(true)
    }
  }, [active])

  return (
    <Slide title="text">
      <div className="text-container">
        <div
          className={classnames({
            wiggle: slideVisited,
          })}
        >
          <FadeInCharactersRandomly
            delay={delay}
            copy={copy}
            start={slideVisited}
          />
        </div>
      </div>
    </Slide>
  )
}

export default TextSlide
