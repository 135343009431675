/* 
    This component is used like so: 
        
    <FadeWords copy="">

    It will draw one word at a time 
                
*/

/* Based on: https://github.com/gkaemmer/react-fade-in/blob/master/src/FadeIn.tsx*/

import React, { useState, useEffect } from "react"
import classnames from "classnames"
import sample from "lodash.sample"

import "./fade-in-characters-randomly.css"

const FadeInCharactersRandomly = ({
  copy,
  delay = 60,
  transitionDuration = 400,
  style = {},
  className = "",
  start = true,
  callback = () => {},
}) => {
  // copy must be a string, add error check here
  const [isFinished, setIsFinished] = useState(false)
  const [characters, setCharacters] = useState([])
  const [totalCharacterCount, setTotalCharacterCount] = useState(0)
  const [
    currentVisibleCharactersCount,
    setCurrentVisibleCharactersCount,
  ] = useState(0)

  useEffect(() => {
    setCharacters(
      copy.split("").map(character => {
        return { character, visible: false }
      })
    )
  }, [copy])

  useEffect(() => {
    if (characters.length > 0) {
      setTotalCharacterCount(characters.length)
    }
  }, [characters])

  // track total word count
  useEffect(() => {
    // only if ready to start
    if (!start || totalCharacterCount <= 0) return

    // If the current word count is the same as the total word count, stop incrementing
    if (currentVisibleCharactersCount >= totalCharacterCount) {
      setIsFinished(true)
      return () => clearTimeout(timeout)
    }

    // move currentVisibleCharactersCount towards totalCharacterCount
    const increment =
      totalCharacterCount > currentVisibleCharactersCount ? 1 : -1
    const timeout = setTimeout(() => {
      // set a new randomly selected character to be visible
      setCharacters(previousCharacters => {
        const invisibleCharacterIndexes = previousCharacters
          .map((character, index) => (character.visible ? false : index))
          .filter(index => index !== false)
        const invisibleCharacterIndex = sample(invisibleCharacterIndexes)
        previousCharacters[invisibleCharacterIndex].visible = true
        const newCharacters = previousCharacters
        return newCharacters
      })
      // increase number of characters visible
      setCurrentVisibleCharactersCount(
        currentVisibleCharactersCount + increment
      )
    }, delay)
    return () => clearTimeout(timeout)
  }, [
    currentVisibleCharactersCount,
    totalCharacterCount,
    delay,
    characters,
    start,
  ])

  useEffect(() => {
    if (!start) {
      if (isFinished) {
        setIsFinished(false)
      }
      if (currentVisibleCharactersCount > 0) {
        setCharacters(
          copy.split("").map(character => {
            return { character, visible: false }
          })
        )
        setCurrentVisibleCharactersCount(0)
      }
    }
  }, [start, isFinished, characters, copy, currentVisibleCharactersCount])

  useEffect(() => {
    if (isFinished && callback) {
      callback()
    }
  }, [isFinished, callback])

  return (
    <p
      aria-label={copy}
      style={style}
      className={classnames(className, "fade-in-characters-randomly")}
    >
      {characters.map((character, index) => {
        return (
          <span
            key={`${character}-${index}`}
            aria-hidden="true"
            style={{
              transition: `opacity ${transitionDuration}ms`,
              opacity: character.visible ? 1 : 0,
            }}
          >
            {character.character}
          </span>
        )
      })}
    </p>
  )
}

export default FadeInCharactersRandomly
