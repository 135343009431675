import React from "react"
import classnames from "classnames"

import "./slide.css"

const Slide = ({ title = null, active = true, children }) => (
  <div className={classnames("slide", active, title)}>{children}</div>
)

export default Slide
